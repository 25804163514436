import styles from "./home.module.css";

const ThirdSection = () => {
  return (
    <div className={styles.third}>
      <h2>
        Focus On Your <span>Fitness</span> Not Your Loss
      </h2>
    </div>
  );
};

export default ThirdSection;
