import { trainersData } from "../../constans/app-contants";
import Heading from "../heading";
import styles from "./trainers.module.css";


const TrainerCard = ({ name, img, specialisations, phone }) => (
  <div className={styles.box} data-aos="fade-up" data-aos-anchor-placement="top-center">
    <img src={img} alt={`${name} trainer`} />
    <div>
      <div>
        <h4>{name}</h4>
        <p>
          <i className="fa-solid fa-star"></i>/5
        </p>
      </div>
      <h5>Specialisations :</h5>
      <p>{specialisations}</p>
      <a href={`https://wa.me/${phone}`} target="_blank" rel="noopener noreferrer">
        <i className="fa-solid fa-phone"></i>
      </a>
    </div>
  </div>
);

const Seventh = () => {
  return (
    <div className={`${styles.trainers} container sections-padding`}>
      <Heading className={styles.trainers_head} title="Our Trainers" subTitle="We Trained You to Gain" />
      <div className={styles.boxes}>
        {trainersData.map((trainer, index) => (
          <TrainerCard key={index} {...trainer} />
        ))}
      </div>
    </div>
  );
};

export default Seventh;
