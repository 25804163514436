import styles from "./home.module.css";
import photo from "../../assets/Video.png";

const amenities = [
  { title: "Trainers", description: "8 Male & Female trainers" },
  { title: "Free-Wifi", description: "Free wifi for Gym members." },
  { title: "Parking Facility", description: "Nice Parking Facility" },
  {
    title: "Changing Rooms",
    description: "Delicious, protein-packed gourmet shakes that come in chocolate, fruit, vanilla and more. Water and snacks too."
  },
  { title: "Restrooms", description: "Separate restrooms for Male and Female." },
];

const Eighth = () => {
  return (
    <div className={styles.eight}>
      <div className={`${styles.eight_content} container sections-padding`}>
        <div className={styles.eight_first}>
          <p>Gym Amenities</p>
          <h2>The Unique Standard</h2>
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point of
            using Lorem Ipsum is
          </p>
          <img src={photo} alt="Gym amenities" />
        </div>
        <div className={styles.eight_second}>
          {amenities.map((amenity, index) => (
            <div key={index}>
              <h5>
                <i className="fa-regular fa-circle-check"></i>
                {amenity.title}
              </h5>
              <p>{amenity.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Eighth;
