import photo3 from "../../assets/quet.png";
import styles from "./home.module.css";
// import photo2 from "../../assets/s2.jpg";

// import Swiper
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Avatar1 from '../../assets/avatar1.png'
import Avatar2 from '../../assets/avatar2.png'

const TESTIMONIALS = [
  {
    name: "Sathish",
    message: 'Nice Place to workout',
    img: Avatar1
  },
  {
    name: "Nitheesh",
    message: 'Very friendly Trainers',
    img: Avatar2
  }
]

const Sixth = () => {
  return (
    <div
      className={`${styles.six}`}
      style={{
        paddingTop: 40
      }}
      data-aos="fade-up"
      data-aos-anchor-placement="center-bottom">
      <div className={`${styles.six_content} container `}>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={30}
          breakpoints={{
            600: {
              slidesPerView: 1,
            },
            800: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}>
          {TESTIMONIALS.map((testimonial, index) => <SwiperSlide>
            <div className={styles.swipe_content}>
              <div className={styles.six_image_holder}>
                <img src={testimonial?.img} alt="" className={styles.trainer} />
                <img src={photo3} alt="" />
              </div>

              <div className={styles.six_text}>
                <h4>{testimonial?.name}</h4>
                {/* <p>Athletics /Trainer</p> */}
                <div className={styles.starts}>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                </div>
                <p>
                  {testimonial?.message}
                </p>
              </div>
            </div>
          </SwiperSlide>)}
        </Swiper>
      </div>
    </div>
  );
};

export default Sixth;
