import { useEffect, useRef, useState } from "react";
// import { useNavigate } from "react-router";
import styles from "./home.module.css";

const FirstSection = () => {
  const videoRef = useRef(null);
  // const navigate = useNavigate();
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const playVideo = () => {
      if (videoRef.current) {
        videoRef.current.muted = true;
        const playPromise = videoRef.current.play();

        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              // Autoplay started
            })
            .catch(error => {
              console.error("Autoplay failed: ", error);
            });
        }
      }
    };

    playVideo();
  }, [videoRef]);

  return (
    <div className={styles.first_section} data-aos="zoom-out-down">
      <video
        ref={videoRef}
        autoPlay
        muted
        controls={false}
        width={dimensions.width}
        height={dimensions.height}
      >
        <source type="video/mp4" src={require("../../assets/videos/bbxhomeintro.mp4")} />
      </video>
    </div>
  );
};

export default FirstSection;
