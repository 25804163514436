import React from "react";

const Heading = ({ title, subTitle, className }) => {
	return (
		<div className={className}>
			<p className="paragraph" style={{ fontSize: 20, fontWeight: "bold" }}>
				{title}
			</p>
			<h2 style={{ fontSize: 25, fontWeight: "bold" }}>{subTitle}</h2>
		</div>
	);
};

export default Heading;
