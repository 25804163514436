import {isMobile} from 'react-device-detect';
import styles from "./home.module.css";

const FivethSection = () => {
  const googleLink = isMobile?'https://www.google.com/search?q=bbx+tirupur&oq=bbx+tirupur&aqs=chrome.0.69i59.2271j0j1&sourceid=chrome&ie=UTF-8&pli=1&authuser=1#lkt=LocalPoiReviews&lpg=cid:CgIgAQ%3D%3D&pli=1&trex=m_t:lcl_akp,rc_f:rln,rc_ludocids:18181310641572445504,ru_gwp:0%252C7,ru_lqi:CgtiYnggdGlydXB1ckiStPTCzraAgAhaDxAAIgtiYnggdGlydXB1cpIBA2d5bZoBI0NoWkRTVWhOTUc5blMwVkpRMEZuU1VOcGQzQTJZbGxCRUFFqgE9EAEqByIDYmJ4KAAyHxABIhu_dJhz_Gnb3klm_KduqYp3pWdLw1XHtQIqtlUyDxACIgtiYnggdGlydXB1cg,trex_id:gmKHwc':"https://www.google.com/search?q=bbx+tirupur&oq=bbx+tirupur&aqs=chrome.0.69i59.2271j0j1&sourceid=chrome&ie=UTF-8&pli=1&authuser=1#lrd=0x3ba9077eb8bc8a83:0xfc50fdb325f47d40,3,,,,&pli=1&rlimm=18181310641572445504"
  return (
    <div className={`${styles.five} container`}>
      <div
        className={styles.five_box}
        data-aos="fade-right"
        data-aos-offset="300"
        data-aos-easing="ease-in-sine">
        <h4>01</h4>
        <p>Fitnes Taining</p>
      </div>
      <div
        className={styles.five_box}
        data-aos="fade-right"
        data-aos-offset="300"
        data-aos-easing="ease-in-sine">
        <h4>02</h4>
        <p>Regular Routine</p>
      </div>
      <div
        className={styles.five_box}
        data-aos="fade-right"
        data-aos-offset="300"
        data-aos-easing="ease-in-sine">
        <h4>03</h4>
        <p>Deit Maintenance</p>
      </div>

      <div
        className={styles.five_box}
        data-aos="fade-right"
        data-aos-offset="300"
        data-aos-easing="ease-in-sine">
        <i className="fa-brands fa-google"></i>
        <div>
          <p>
            <a
              href={googleLink}
              target="_blank">
              Review on Google
            </a></p>
          <div>
            <i className="fa-solid fa-star"></i>
            <i className="fa-solid fa-star"></i>
            <i className="fa-solid fa-star"></i>
            <i className="fa-solid fa-star"></i>
            <i className="fa-solid fa-star"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FivethSection;
