import karthick from "../assets/karthick.jpeg";
import Karna from "../assets/karna.jpeg";
import Vicky from "../assets/Vicky.jpeg";
import hari from "../assets/hari.jpeg";
import subash from "../assets/subash.jpeg";
import jana from "../assets/jana.jpeg";
import mohan from "../assets/mohan.jpeg";

export const trainersData = [
	{
		name: "Hari",
		img: hari,
		specialisations: "Crossfit Expert, Nutrition & Rehab",
		phone: "+919751061120",
	},
	{
		name: "Karthi",
		img: karthick,
		specialisations: "Crossfit Expert, Nutrition & Rehab",
		phone: "+919751061120",
	},
	{
		name: "Jana",
		img: jana,
		specialisations: "Crossfit Expert, Nutrition & Rehab",
		phone: "+919751061120",
	},
	{
		name: "Mohan",
		img: mohan,
		specialisations: "Crossfit Expert, Nutrition & Rehab",
		phone: "+919751061120",
	},
	{
		name: "Subash",
		img: subash,
		specialisations: "Crossfit Expert, Nutrition & Rehab",
		phone: "+919751061120",
	},
	{
		name: "Karna",
		img: Karna,
		specialisations: "Crossfit Expert, Nutrition & Rehab",
		phone: "+919751061120",
	},
	{
		name: "Vicky",
		img: Vicky,
		specialisations: "Crossfit Expert, Nutrition & Rehab",
		phone: "+919751061120",
	},
];
